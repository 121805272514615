<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title" i18n="@@users">Users</h3>
            </div>
            <div class="card-body">
                <div class="row action-bar">
                    <div class="col-6">
                        <button type="button" class="btn btn-default"
                            [ngClass]="{ 'btn-success': refreshStatus === 'success', 'btn-danger': refreshStatus === 'error' }"
                            (click)="refresh(pager.currentPage)"><i class="fas fa-sync"></i>
                            <span i18n="@@refresh">Refresh</span></button>
                        <button type="button" class="btn btn-default"
                            (click)="openModalCreateUser(editUserModalTemplate)"><i class="fa fa-plus"></i> <span
                                i18n="@@add">Add</span></button>
                    </div>
                    <div class="col-6">
                        <div class="dataTables_length text-right">
                            <label for="pageSize" i18n="@@nbEntries">Nb entries</label>
                            <select id="pageSize" name="pageSize" class="datatable-nb-entries form-control input-sm"
                                [(ngModel)]="pageSize" (ngModelChange)="refresh(pager.currentPage)">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th i18n="@@username">Username</th>
                                    <th i18n="@@email">Email</th>
                                    <th i18n="@@createdAt">Created at</th>
                                    <th i18n="@@isAdmin">Is Admin</th>
                                    <th i18n="@@enabled">Enabled</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of pagedItems">
                                    <td>{{ user.id }}</td>
                                    <td>{{ user.firstname }} {{ user.lastname }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.createdAt | date:'medium' }}</td>
                                    <td *ngIf="isAdmin(user)" class="item-clickable text-green" (click)="grant(user.id)">
                                        <i class="far fa-check-square"></i></td>
                                    <td *ngIf="!isAdmin(user)" class="item-clickable text-red" (click)="grant(user.id)">
                                        <i class="far fa-square"></i></td>
                                    <td *ngIf="user.enabled" class="text-green"><i class="far fa-check-square"></i></td>
                                    <td *ngIf="!user.enabled" class="text-red"><i class="far fa-square"></i></td>
                                    <td class="action-bar">
                                        <a class="text-muted item-clickable"
                                            (click)="openModalEditUser(editUserModalTemplate, user)">
                                            <i class="fas fa-pencil-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div *ngIf="pager.pages && pager.pages.length" class="dataTables_info">
                            <span i18n="@@pageShowing">Showing </span>
                            <span>{{ pager.startIndex + 1 }}</span>
                            <span i18n="@@pageTo"> to </span>
                            <span>{{ pager.endIndex + 1 }}</span>
                            <span i18n="@@pageOf"> of </span>
                            <span>{{ pager.totalItems }}</span>
                            <span i18n="@@pageEntries"> entries</span>
                        </div>
                    </div>
                    <div class="col-7">
                        <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end">
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                <a class="page-link" (click)="refresh(1)">&#171;</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                <a class="page-link" (click)="refresh(pager.currentPage - 1)">&#8249;</a>
                            </li>
                            <li class="page-item" *ngFor="let page of pager.pages"
                                [ngClass]="{active:pager.currentPage === page}">
                                <a class="page-link" (click)="refresh(page)">{{page}}</a>
                            </li>
                            <li class="page-item next" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a class="page-link" (click)="refresh(pager.currentPage + 1)">&#8250;</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a class="page-link" (click)="refresh(pager.totalPages)">&#187;</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #editUserModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title" id="editUserModalLabel">{{ f['firstname'].value }} {{ f['lastname'].value }}</h4>
        <button type="button" class="close" (click)="closeModalEditUser()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editUserForm">
            <div class="form-group" [ngClass]="{ 'has-error': f['email'].errors }">
                <label i18n="@@email">Email</label>
                <input type="email" formControlName="email" class="form-control" placeholder="Email"
                    i18n-placeholder="@@email">
            </div>
            <div class="form-group"
                [ngClass]="{ 'has-error': userEdited.id === undefined && f['password'].value === '' }">
                <label i18n="@@password">Password</label>
                <input type="password" formControlName="password" class="form-control"
                    placeholder="Password - Keep empty to leave it unchanged" i18n-placeholder="@@passwordPlaceholder">
            </div>
            <div class="form-group">
                <label i18n="@@firstname">Firstname</label>
                <input type="text" formControlName="firstname" class="form-control" placeholder="Firstname"
                    i18n-placeholder="@@firstname">
            </div>
            <div class="form-group">
                <label i18n="@@lastname">Lastname</label>
                <input type="text" formControlName="lastname" class="form-control" placeholder="Lastname"
                    i18n-placeholder="@@lastname">
            </div>
            <div class="form-group">
                <label i18n="@@avatarUrl">Avatar URL</label>
                <input type="url" formControlName="avatarUrl" class="form-control" placeholder="Avatar URL"
                    i18n-placeholder="@@avatarUrl">
            </div>
            <div class="form-group">
                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" class="custom-control-input" id="isActivated" formControlName="isActivated">
                    <label class="custom-control-label" for="isActivated" i18n="@@enabled">Enabled</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button [disabled]="submitting" class="btn btn-primary" (click)="onSubmit()" i18n="@@save">Save</button>
    </div>
</ng-template>