<!-- Header Navbar: style can be found in header.less -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">

    <!-- Left navbar links -->
    <ul class="navbar-nav navbar-left">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-sm-inline-block nav-title">
            <a>{{ navService.getTitle() }}</a>
        </li>
    </ul>

    <!-- Navbar Right Menu -->
    <ul class="navbar-nav ml-auto">

        <li class="nav-item">
            <a class="nav-link item-clickable" (click)="logout()">
                <i class="fas fa-sign-out-alt"></i>
            </a>
        </li>


    </ul>

</nav>