<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-8">
            <div class="card">
                <div class="card-header with-border">
                    <h3 i18n="@@detailedPresentation" class="card-title">Detailed Presentation</h3>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <quill-editor id="longDescription" formControlName="longDescription"
                            (onEditorCreated)="onImageUpload($event)" [sanitize]="true"></quill-editor>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card">
                <div class="card-header with-border">
                    <h3 class="card-title" i18n="@@informations">Informations</h3>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="title" i18n="@@title">Title</label>
                        <input type="text" formControlName="title" class="form-control" id="title" placeholder="Title"
                            i18n-placeholder="@@title"
                            [ngClass]="{ 'is-invalid': this.form.controls['title'].errors }" />
                    </div>
                    <div class="form-group">
                        <label for="shortDescription" i18n="@@shortDescription">Short Description</label>
                        <input formControlName="shortDescription" type="text" class="form-control" id="shortDescription"
                            placeholder="Short Description" i18n-placeholder="@@shortDescription"
                            [ngClass]="{ 'is-invalid': this.form.controls['shortDescription'].errors }" />
                    </div>
                    <div class="form-group" *ngIf="id > 0 && project.status === projectStatus.IN_PROGRESS" >
                        <label for="peopleRequired" i18n="@@peopleNeededInAdditionOfProjectLeader">People needed (in
                            addition of the project leader)</label>
                        <input formControlName="peopleRequired" type="number" class="form-control" id="peopleRequired"
                            min="2" [ngClass]="{ 'is-invalid': this.form.controls['peopleRequired'].errors }" />
                    </div>
                </div>
                <div class="card-footer">
                    <div class="text-center">
                        <a *ngIf="id == 0" class="btn btn-app bg-gray" (click)="onSubmit(projectStatus.DRAFT)">
                            <i class="fas fa-save"></i> <span i18n="@@saveAsDraft">Save as draft</span>
                        </a>
                        <a *ngIf="id == 0" class="btn btn-app bg-yellow" (click)="openPublishIdeaModal(publishIdeaModalRef)">
                            <i class="fas fa-lightbulb"></i> <span i18n="@@editProjectPublishAsIdea">Publish as Idea</span>
                        </a>
                        <a *ngIf="id > 0" class="btn btn-app bg-secondary" (click)="onSubmit()">
                            <i class="fas fa-save"></i> <span i18n="@@save">Save</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<ng-template #publishIdeaModalRef>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@publishIdea">Publish the idea</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="publishIdeaModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="publishIdeaForm">
            <div class="form-group">
                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" class="custom-control-input" id="ideaHasAnonymousCreator"
                        formControlName="ideaHasAnonymousCreator">
                    <label class="custom-control-label" for="ideaHasAnonymousCreator"
                        i18n="@@publishIdeaAnonymously">Publish this idea anonymously</label>
                </div>
            </div>
            <div class="form-group" *ngIf="!publishIdeaForm.controls['ideaHasAnonymousCreator'].value">
                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" class="custom-control-input" id="ideaHasLeaderCreator"
                        formControlName="ideaHasLeaderCreator">
                    <label class="custom-control-label" for="ideaHasLeaderCreator" i18n="@@userWhantToLeadRealOfIdea">I
                        want to lead the realization of the idea</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="onSubmit(projectStatus.IDEA)" i18n="@@submit">Submit</button>
    </div>
</ng-template>
