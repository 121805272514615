<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header with-border">
                    <h3 i18n="@@newsEdition" class="card-title" *ngIf="idProject === 0">News edition</h3>
                    <h3 class="card-title" *ngIf="idProject > 0"><span i18n="@@newEditionForProject">News edition for
                            the project </span><b>{{ project.title }}</b></h3>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="title" i18n="@@title">Title</label>
                        <input type="text" formControlName="title" class="form-control" id="title" placeholder="Title"
                            i18n-placeholder="@@title"
                            [ngClass]="{ 'is-invalid': this.form.controls['title'].errors }" />
                    </div>
                    <div class="form-group">
                        <quill-editor id="content" formControlName="content" (onEditorCreated)="onImageUpload($event)" [sanitize]="true"></quill-editor>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="text-center">
                        <button [disabled]="submitting" type="button" class="btn btn-primary" (click)="onSubmit()"
                            i18n="@@save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>