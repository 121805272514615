<div class="row">
    <div class="col-md-3">

        <!-- Profile Image -->
        <div class="card">
            <div class="card-body box-profile">

                <div class="text-center">
                    <img class="profile-user-img img-fluid img-circle" [src]="user.avatarUrl" alt="User profile picture">
                </div>
                <h3 class="profile-username text-center">{{ user.firstname }} {{ user.lastname }}</h3>
                <p class="text-muted text-center">
                    <span i18n="@@memberSince">Member since </span>
                    <span>{{ user.createdAt | date:'shortDate' }}</span>
                </p>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->

        <!-- About Me Box -->
        <div class="card">
            <div class="card-header with-border">
                <h3 class="card-title" i18n="@@myAccounts">My accounts</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="project_progress" *ngFor="let account of accounts | keyvalue">
                    <span class="progress-text">{{ getBudget(account.value.budget.id).name }}</span>
                    <div class="progress sm" title="{{ account.value.usage }}">
                        <div class="progress-bar bg-aqua" *ngIf="getBudget(account.value.budget.id).isUsable" [ngStyle]="{'width': account.value.usage }"></div>
                        <div class="progress-bar bg-gray" *ngIf="!getBudget(account.value.budget.id).isUsable" [ngStyle]="{'width': account.value.usage }"></div>
                    </div>
                    <small>
                        {{ account.value.amount }} € / {{ account.value.initialAmount }} €
                    </small>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.col -->
    <div class="col-md-9">
        <tabset class="card card-outline">
            <tab heading="Projects" id="projects" i18n-heading="@@projects">
                <table class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th i18n="@@title">Title</th>
                            <th i18n="@@shortDescription">Short Description</th>
                            <th style="width: 40px" i18n="@@status">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let project of userProjects">
                            <td><a [routerLink]="['/projects/', project.id]">{{ project.title }}</a></td>
                            <td>{{ project.shortDescription }}</td>
                            <td>
                                <span *ngIf="project.status === projectStatus.DRAFT" class="badge bg-gray" i18n="@@profileStatusDraft">Draft</span>
                                <span *ngIf="project.status === projectStatus.IDEA" class="badge bg-blue" i18n="@@profileStatusIdea">Idea</span>
                                <span *ngIf="project.status === projectStatus.IN_PROGRESS" class="badge bg-yellow" i18n="@@profileStatusInProgress">In Progress</span>
                                <span *ngIf="project.status === projectStatus.ON_PAUSE" class="badge bg-gray" i18n="@@profileStatusOnPause">On Pause</span>
                                <span *ngIf="project.status === projectStatus.FINISHED" class="badge bg-green" i18n="@@profileStatusFinished">Finished</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </tab>
            <tab heading="Contributions" id="contributions" i18n-heading="@@contributions">
                <table class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th i18n="@@budget">Budget</th>
                            <th i18n="@@date">Date</th>
                            <th i18n="@@project">Project</th>
                            <th i18n="@@amount">Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let donation of donations | keyvalue">
                            <td>{{ getBudget(getAccount(donation.value.account.id).budget.id).name }}</td>
                            <td>{{ donation.value.createdAt | date:'medium' }}</td>
                            <td><a *ngIf="campaigns.get(donation.value.campaign.id) !== undefined" [routerLink]="['/projects/', getCampaign(donation.value.campaign.id).project.id]">{{ getProject(getCampaign(donation.value.campaign.id).project.id).title }}</a></td>
                            <td>{{ donation.value.amount }} €</td>
                            <td>
                                <a *ngIf="campaigns.get(donation.value.campaign.id) !== undefined && getCampaign(donation.value.campaign.id).status === 'IN_PROGRESS'" class="text-muted item-clickable" [ngClass]="{ 'text-danger': deleteDonationsStatus[donation.value.id] === 'error' }" (click)="deleteDonations(donation.value)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </tab>
            <tab heading="Settings" id="settings" class="p-3" i18n-heading="@@settings">
                <form class="form-horizontal" [formGroup]="editUserForm">
                    <div class="form-group">
                        <label for="inputEmail" class="control-label" i18n="@@email">Email</label>
                        <input formControlName="email" type="email" class="form-control" id="inputEmail" placeholder="Email" i18n-placeholder="@@email">
                    </div>
                    <div class="form-group">
                        <label for="inputPassword" class="control-label" placeholder="Email" i18n="@@password">Password</label>
                        <input formControlName="password" type="password" class="form-control" id="inputPassword" placeholder="Password - Keep empty to leave it unchanged" i18n-placeholder="@@passwordPlaceholder">
                    </div>
                    <div class="form-group">
                        <label for="inputFirstname" class="control-label" i18n="@@firstname">Firstname</label>
                        <input formControlName="firstname" type="text" class="form-control" id="inputFirstname" placeholder="Firstname" i18n-placeholder="@@firstname">
                    </div>
                    <div class="form-group">
                        <label for="inputLastname" class="control-label" placeholder="Email" i18n="@@lastname">Lastname</label>
                        <input formControlName="lastname" type="text" class="form-control" id="inputLastname" placeholder="Lastname" i18n-placeholder="@@lastname">
                    </div>
                    <div class="form-group">
                        <label for="inputAvatarUrl" class="control-label" i18n="@@avatarUrl">Avatar URL</label>
                        <input formControlName="avatarUrl" type="url" class="form-control" id="inputAvatarUrl" placeholder="Avatar URL" i18n-placeholder="@@avatarUrl">
                    </div>
                    <button type="submit" [disabled]="submitting" class="btn btn-primary" [ngClass]="{ 'btn-success': submitStatus === 'success', 'btn-danger': submitStatus === 'error' }" (click)="submit()" i18n="@@save">Save</button>
                </form>
            </tab>
            <tab heading="Developper" id="dev" i18n-heading="@@developper">
                <table class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let apiToken of apiTokens | keyvalue">
                            <td>{{ apiToken.value.id }}</td>
                            <td>
                                <a class="text-muted item-clickable" [ngClass]="{ 'text-danger': deleteApiTokenStatus[apiToken.value.id] === 'error' }" (click)="deleteApiToken(apiToken.value)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="bottom-bar">
                    <button type="button" class="btn btn-primary" (click)="generateApiToken(template)" [ngClass]="{ 'btn-success': generateApiTokenStatus === 'success', 'btn-danger': generateApiTokenStatus === 'error' }">
                        <i class="fa fa-plus"></i> <span i18n="@@generate">Generate</span>
                    </button>
                </div>
                <ng-template #template>
                    <div class="modal-header">
                        <h4 class="modal-title pull-left" i18n="@@yourNewApiToken">Your new API Token</h4>
                        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <code id="generatedApiToken">{{ generatedApiToken.token }}</code>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" (click)="modalRef.hide()" i18n="@@close">Close</button>
                    </div>
                </ng-template>
            </tab>
        </tabset>
    </div>
</div>

<div class="toasts-top-right fixed" *ngIf="showConfirmDeleteDonationToast">
    <div class="toast bg-success fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header"><strong class="mr-auto">Merci</strong><button
                data-dismiss="toast" type="button" class="ml-2 mb-1 close" aria-label="Close"><span
                    aria-hidden="true">×</span></button></div>
        <div class="toast-body">Votre contribution va être supprimée. Patientez et rafraîchissez la page.</div>
    </div>
</div>
