<div class="row">
    <div class="col-8">
        <div *ngIf="id > 0" class="card">
            <div class="card-header">
                <h3 class="card-title" i18n="@@members">Members</h3>
                <div class="card-tools">
                    <a class="btn btn-tool btn-sm" (click)="refreshMembers(pagerMembers.currentPage)"
                        [ngClass]="{ 'text-success': refreshMembersStatus === 'success', 'text-danger': refreshMembersStatus === 'error'}">
                        <i class="fas fa-sync"></i>
                    </a>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <form class="form-horizontal action-bar" [formGroup]="addMemberOrgForm">
                    <div class="form-group row">
                        <label for="inputEmail" id="inputEmailLabel" class="col-2 control-label float-right"
                            i18n="@@addMember">Add member : </label>
                        <div class="col-8">
                            <input formControlName="email" type="email" class="form-control" formControlName="email"
                                placeholder="Email" i18n-placeholder="@@userEmail">
                        </div>
                        <div class="col-1">
                            <button type="button" class="btn btn-flat btn-default"
                                [ngClass]="{ 'btn-success': addStatus === 'success', 'btn-danger': addStatus === 'error', 'btn-default': addStatus === 'idle' }"
                                (click)="onSubmitEmail()" [disabled]="submittingEmail"><i class="fa fa-plus"></i>
                                <span i18n="@@add">Add</span></button>
                        </div>
                    </div>
                </form>
                <hr />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th i18n="@@name">Name</th>
                                <th i18n="@@email">Email</th>
                                <th i18n="@@isSponsor">Is Sponsor ?</th>
                                <th i18n="@@isManager">Is Manager ?</th>
                                <th i18n="@@isOwner">Is Owner ?</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of pagedItemsMembers">
                                <td>{{ user.firstname }} {{ user.lastname }}</td>
                                <td>{{ user.email }}</td>
                                <td *ngIf="user.isUserSponsor" class="item-clickable text-green"
                                    (click)="grant(user.id, 'ROLE_SPONSOR')"><i class="far fa-check-square"></i></td>
                                <td *ngIf="!user.isUserSponsor" class="item-clickable text-red"
                                    (click)="grant(user.id, 'ROLE_SPONSOR')"><i class="far fa-square"></i></td>
                                <td *ngIf="user.isUserManager" class="item-clickable text-green"
                                    (click)="grant(user.id, 'ROLE_MANAGER')"><i class="far fa-check-square"></i></td>
                                <td *ngIf="!user.isUserManager" class="item-clickable text-red"
                                    (click)="grant(user.id, 'ROLE_MANAGER')"><i class="far fa-square"></i></td>
                                <td *ngIf="user.isUserOwner" class="item-clickable text-green"
                                    (click)="grant(user.id, 'ROLE_OWNER')"><i class="far fa-check-square"></i></td>
                                <td *ngIf="!user.isUserOwner" class="item-clickable text-red"
                                    (click)="grant(user.id, 'ROLE_OWNER')"><i class="far fa-square"></i></td>
                                <td class="action-bar">
                                    <a class="text-muted item-clickable" (click)="onRemoveMember(user)">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="slackSyncStatus === 'running' || refreshMembersStatus === 'running'" class="overlay">
                <i class="fas fa-sync fa-spin"></i>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <div class="row">
                    <div class="col-5">
                        <div *ngIf="pagerMembers.pages && pagerMembers.pages.length" class="dataTables_info">
                            <span i18n="@@pageShowing">Showing </span>
                            <span>{{ pagerMembers.startIndex + 1 }}</span>
                            <span i18n="@@pageTo"> to </span>
                            <span>{{ pagerMembers.endIndex + 1 }}</span>
                            <span i18n="@@pageOf"> of </span>
                            <span>{{ pagerMembers.totalItems }}</span>
                            <span i18n="@@pageEntries"> entries</span>
                        </div>
                    </div>
                    <div class="col-7">
                        <ul *ngIf="pagerMembers.pages && pagerMembers.pages.length"
                            class="pagination justify-content-end">
                            <li class="page-item" [ngClass]="{disabled:pagerMembers.currentPage === 1}">
                                <a class="page-link" (click)="refreshMembers(1)">&#171;</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pagerMembers.currentPage === 1}">
                                <a class="page-link" (click)="refreshMembers(pagerMembers.currentPage - 1)">&#8249;</a>
                            </li>
                            <li class="page-item" *ngFor="let page of pagerMembers.pages"
                                [ngClass]="{active:pagerMembers.currentPage === page}">
                                <a class="page-link" (click)="refreshMembers(page)">{{page}}</a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:pagerMembers.currentPage === pagerMembers.totalPages}">
                                <a class="page-link" (click)="refreshMembers(pagerMembers.currentPage + 1)">&#8250;</a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:pagerMembers.currentPage === pagerMembers.totalPages}">
                                <a class="page-link" (click)="refreshMembers(pagerMembers.totalPages)">&#187;</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="row">
            <div class="col-12">
                <div class="card card-primary">
                    <div class="card-header with-border">
                        <h3 class="card-title" i18n=@@informations>Informations</h3>
                    </div>
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form [formGroup]="editOrgForm">
                        <div class="card-body">
                            <div class="form-group has-feedback" [ngClass]="{ 'has-error': f['name'].errors }">
                                <label for="name" i18n="@@name">Name</label>
                                <input type="text" formControlName="name" class="form-control" id="name"
                                    placeholder="Enter organization name" [(ngModel)]="organization.name">
                            </div>
                            <div class="form-group has-feedback" [ngClass]="{ 'has-error': f['name'].errors }">
                              <label for="name" i18n="@@socialOrganizationName">Name with pronoun</label>
                              <input type="text" formControlName="socialName" class="form-control" id="social-organization-name"
                                     placeholder="Enter social organization name" [(ngModel)]="organization.socialName">
                            </div>
                            <div class="form-group has-feedback" [ngClass]="{ 'has-error': f['logoUrl'].errors }">
                                <label for="logoUrl" i18n="@@logoUrl">Logo URL</label>
                                <input type="url" formControlName="logoUrl" class="form-control" id="logoUrl"
                                    placeholder="Enter organization logo URL" [(ngModel)]="organization.logoUrl">
                            </div>
                            <div *ngIf="this.organization.slackTeam && organization.slackTeam.id > 0">
                              <div class="form-group has-feedback" [ngClass]="{ 'has-error': f['slackPublicationChannelId'].errors }">
                                <label i18n="@@slackPublicationChannelId">Slack Publication Channel ID</label>
                                <input type="text" formControlName="slackPublicationChannelId" [(ngModel)]="organization.slackTeam.publicationChannelId" class="form-control" id="slackPublicationChannelId" placeholder="Enter channel id for slack">
                              </div>
                            </div>
                            <div *ngIf="msTeam && msTeam.id > 0" class="form-group has-feedback" [ngClass]="{ 'has-error': f['msPublicationGroupId'].errors }">
                                <label for="msPublicationGroupId" i18n="@@msPublicationGroupId">Microsoft Publication Group ID</label>
                                <input type="text" formControlName="msPublicationGroupId" [(ngModel)]="msTeam.groupId" class="form-control" id="msPublicationGroupId" placeholder="Microsoft Team Group ID">
                            </div>
                            <div *ngIf="msTeam && msTeam.id > 0" class="form-group has-feedback" [ngClass]="{ 'has-error': f['msPublicationChannelId'].errors }">
                                <label for="msPublicationChannelId" i18n="@@msPublicationChannelId">Microsoft Publication Channel ID</label>
                                <input type="text" formControlName="msPublicationChannelId" [(ngModel)]="msTeam.channelId" class="form-control" id="msPublicationChannelId" placeholder="Microsoft Team Channel ID">
                            </div>
                            <div *ngIf="msTeam && msTeam.id > 0" class="form-group has-feedback" [ngClass]="{ 'has-error': f['msCompanyFilter'].errors }">
                                <label for="msCompanyFilter" i18n="@@msCompanyFilter">Microsoft Company Filter</label>
                                <input type="text" formControlName="msCompanyFilter" [(ngModel)]="msTeam.companyFilter" class="form-control" id="msCompanyFilter" placeholder="Microsoft Company Filter">
                            </div>
                            <div *ngIf="id > 0" class="form-group action-bar">
                                <label i18n="@@thirdApps">Third Apps</label>
                                <table class="table table-striped table-valign-middle">
                                    <thead>
                                        <tr>
                                            <th i18n="@@app">App</th>
                                            <th i18n="@@appStatus">Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="slackEnabled && organization.msTeam.id <= 0">
                                            <td>Slack</td>
                                            <td *ngIf="organization.slackTeam.id <= 0"><span
                                                    i18n="@@notConnectedYet">Not connected Yet</span></td>
                                            <td *ngIf="organization.slackTeam.id > 0">
                                                <span i18n="@@connectedWithSlackTeam">Connected with Slack Team :
                                                </span>
                                                <b>
                                                    <a href="https://app.slack.com/client/{{ organization.slackTeam.teamId }}"
                                                        target="_blank" rel="noopener noreferrer">{{
                                                        organization.slackTeam.teamName }}</a>
                                                </b>
                                            </td>
                                            <td class="action-bar">
                                                <div *ngIf="organization.slackTeam.id <= 0">
                                                    <a href="https://slack.com/oauth/v2/authorize?client_id={{ slackClientId }}&scope=app_mentions:read,chat:write,commands,im:history,im:write,team:read,users:read,users:read.email&user_scope=team:read,users:read,users:read.email&redirect_uri={{ redirectUrlSlackOAuth }}"
                                                        class="btn btn-social bg-maroon">
                                                        <i class="fab fa-slack"></i>
                                                        <span i18n="@@addToSlack">Add to Slack</span></a>
                                                </div>
                                                <a *ngIf="organization.slackTeam.id > 0"
                                                    [ngClass]="{ 'text-success': slackSyncStatus === 'success', 'text-danger': slackSyncStatus === 'error', 'disabled': slackSyncStatus === 'running' || slackDisconnectStatus === 'running' }"
                                                    (click)="onSlackSync()" class="text-muted item-clickable">
                                                    <i class="fas fa-sync"></i>
                                                </a>
                                                <a *ngIf="organization.slackTeam.id > 0"
                                                    [ngClass]="{ 'text-success': slackDisconnectStatus === 'success', 'text-danger': slackDisconnectStatus === 'error', 'disabled': slackSyncStatus === 'running' || slackDisconnectStatus === 'running' }"
                                                    (click)="onSlackDisconnect()" class="text-muted item-clickable">
                                                    <i class="fas fa-times"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="microsoftEnabled && organization.msTeam.id <= 0">
                                            <td>Microsoft</td>
                                            <td *ngIf="organization.msTeam.id <= 0">
                                                <span i18n="@@notConnectedYet">Not connected Yet</span>
                                            </td>
                                            <td *ngIf="organization.msTeam.id > 0">
                                                <span i18n="@@connectedWithMicrosoft">Connected with Microsoft :
                                                </span>
                                                <b>{{ msTeam.displayName }}</b>
                                            </td>
                                            <td class="action-bar">
                                                <a *ngIf="organization.msTeam.id <= 0"
                                                    href="https://login.microsoftonline.com/{{ microsoftTenantId }}/adminconsent?client_id={{ microsoftClientId }}&state={{ microsoftState }}&redirect_uri={{ microsoftRedirectUrl }}"
                                                    class="btn btn-social bg-warning">
                                                    <i class="fab fa-microsoft"></i>
                                                    <span i18n="@@addToMicrosoft">Add to Microsoft</span></a>
                                                <a *ngIf="organization.msTeam.id > 0"
                                                    [ngClass]="{ 'text-success': microsoftSyncStatus === 'success', 'text-danger': microsoftSyncStatus === 'error', 'disabled': microsoftSyncStatus === 'running' || msDisconnectStatus === 'running' }"
                                                    (click)="onMsSync()" class="text-muted item-clickable">
                                                    <i class="fas fa-sync"></i>
                                                </a>
                                                <a *ngIf="organization.msTeam.id > 0"
                                                    [ngClass]="{ 'text-success': msDisconnectStatus === 'success', 'text-danger': msDisconnectStatus === 'error' }"
                                                    (click)="onMsDisconnect()" class="text-muted item-clickable">
                                                    <i class="fas fa-times"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer" style="text-align: center">
                            <button type="submit" class="btn btn-primary"
                                [ngClass]="{ 'btn-success': submitStatus === 'success', 'btn-danger': submitStatus === 'error' }"
                                (click)="onSubmit()" [disabled]="submitting">
                                <i class="fas fa-save"></i>
                                <span i18n="@@save">Save</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="id > 0" class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" i18n="@@documents">Documents</h3>
                        <div class="card-tools">
                            <a class="btn btn-tool btn-sm" (click)="openNewContentModal(editContentModalTemplate)">
                                <i class="fa fa-plus"></i>
                            </a>
                            <a class="btn btn-tool btn-sm" (click)="refreshContents(pagerContents.currentPage)"
                                [ngClass]="{ 'text-success': refreshContentStatus === 'success', 'text-danger': refreshContentStatus === 'error'}">
                                <i class="fas fa-sync"></i>
                            </a>
                        </div>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th i18n="@@name">Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let content of pagedItemsContents">
                                    <td>{{ content.name }}</td>
                                    <td class="action-bar">
                                        <a class="text-muted item-clickable"
                                            (click)="openContentModal(editContentModalTemplate, content)">
                                            <i class="fas fa-pencil-alt"></i>
                                        </a>
                                        <a class="text-muted item-clickable" (click)="onRemoveContent(content)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="refreshContentStatus === 'running'" class="overlay">
                        <i class="fas fa-sync fa-spin"></i>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-5">
                                <div *ngIf="pagerContents.pages && pagerContents.pages.length" class="dataTables_info">
                                    <span i18n="@@pageShowing">Showing </span>
                                    <span>{{ pagerContents.startIndex + 1 }}</span>
                                    <span i18n="@@pageTo"> to </span>
                                    <span>{{ pagerContents.endIndex + 1 }}</span>
                                    <span i18n="@@pageOf"> of </span>
                                    <span>{{ pagerContents.totalItems }}</span>
                                    <span i18n="@@pageEntries"> entries</span>
                                </div>
                            </div>
                            <div class="col-7">
                                <ul *ngIf="pagerContents.pages && pagerContents.pages.length"
                                    class="pagination justify-content-end">
                                    <li class="page-item" [ngClass]="{disabled:pagerContents.currentPage === 1}">
                                        <a class="page-link" (click)="refreshContents(1)">&#171;</a>
                                    </li>
                                    <li class="page-item" [ngClass]="{disabled:pagerContents.currentPage === 1}">
                                        <a class="page-link"
                                            (click)="refreshContents(pagerContents.currentPage - 1)">&#8249;</a>
                                    </li>
                                    <li class="page-item" *ngFor="let page of pagerContents.pages"
                                        [ngClass]="{active:pagerContents.currentPage === page}">
                                        <a class="page-link" (click)="refreshContents(page)">{{page}}</a>
                                    </li>
                                    <li class="page-item"
                                        [ngClass]="{disabled:pagerContents.currentPage === pagerContents.totalPages}">
                                        <a class="page-link"
                                            (click)="refreshContents(pagerContents.currentPage + 1)">&#8250;</a>
                                    </li>
                                    <li class="page-item"
                                        [ngClass]="{disabled:pagerContents.currentPage === pagerContents.totalPages}">
                                        <a class="page-link"
                                            (click)="refreshContents(pagerContents.totalPages)">&#187;</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #editContentModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@editContent">Edit content</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="contentForm">
            <div class="form-group has-feedback" [ngClass]="{ 'has-error': contentForm.controls['name'].errors }">
                <label for="name" i18n="@@name">Name</label>
                <input type="text" formControlName="name" class="form-control" id="name"
                    placeholder="Enter content name">
            </div>
            <div class="form-group has-feedback"
                [ngClass]="{ 'has-error': contentForm.controls['value'].errors }">
                <label for="value" i18n="@@content">Content</label>
                <quill-editor id="content" formControlName="value" (onEditorCreated)="onImageUpload($event)" [sanitize]="true"></quill-editor>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="onSubmitContent()" i18n="@@save">Save</button>
    </div>
</ng-template>
