<div class="card">
    <div class="card-header">
        <h3 class="card-title" i18n="@@budgets">Budgets</h3>

        <div class="card-tools">
            <a class="btn btn-tool btn-sm" (click)="refresh()"
                [ngClass]="{ 'text-success': refreshStatus === 'success', 'text-danger': refreshStatus === 'error'}">
                <i class="fas fa-sync"></i>
            </a>
            <a class="btn btn-tool btn-sm" (click)="openNewBudgetModal(editBudgetModalTemplate)">
                <i class="fa fa-plus"></i>
            </a>
        </div>
    </div>
    <div class="card-body p-0 table-responsive">
        <table class="table table-striped projects">
            <thead>
                <tr>
                    <th style="width: 1%">#</th>
                    <th i18n="@@name">Name</th>
                    <th i18n="@@amountPerMember">Amount per member</th>
                    <th i18n="@@timeFounding">Time Founding</th>
                    <th i18n="@@startDate">Starting Date</th>
                    <th i18n="@@endDate">Ending Date</th>
                    <th i18n="@@status" class="project-state">Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of budgets | keyvalue">
                    <td> # </td>
                    <td>
                        <a> {{ item.value.name }} </a>
                        <br />
                        <small>
                            <span i18n="@@createdAtInline">Created at </span>
                            <span>{{ item.value.createdAt | date:'medium'}}</span>
                        </small>
                    </td>
                    <td> {{ item.value.amountPerMember.toFixed(2) }} € </td>
                    <td *ngIf="!item.value.canFinanceTime">❌</td>
                    <td *ngIf="item.value.canFinanceTime"> {{ item.value.costOfDay }} € / <span i18n="@@day">day</span><br/>{{ item.value.costOfHour }} € / <span i18n="@@hour">hour</span></td>
                    <td> {{ item.value.startDate | date:'longDate' }} </td>
                    <td> {{ item.value.endDate | date:'longDate' }} </td>
                    <td class="project-state">
                        <span *ngIf="!item.value.isDistributed" class="badge bg-yellow"
                            i18n="@@preparing">Preparing</span>
                        <span *ngIf="item.value.isDistributed" class="badge bg-green"
                            i18n="@@distributed">Distributed</span>
                    </td>
                    <td class="project-actions text-right action-bar">
                        <a *ngIf="!item.value.isDistributed" class="btn btn-primary btn-sm"
                            (click)="distribute(item.key)">
                            <i class="fas fa-paper-plane">
                            </i>
                            <span i18n="@@distribute">Distribute</span>
                        </a>
                        <a class="btn btn-info btn-sm" (click)="openBudgetModal(editBudgetModalTemplate, item.value)">
                            <i class="fas fa-pencil-alt">
                            </i>
                            <span i18n="@@edit">Edit</span>
                        </a>
                        <a *ngIf="!item.value.isDistributed" class="btn btn-danger btn-sm" (click)="delete(item.value)">
                            <i class="fas fa-trash">
                            </i>
                            <span i18n="@@delete">Delete</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="refreshStatus === 'running'" class="overlay">
        <i class="fas fa-sync fa-spin"></i>
    </div>
</div>

<ng-template #editBudgetModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@editBudget">Edit budget</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="editBudgetModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editBudgetForm">
            <div class="form-group">
                <label for="name" i18n="@@name">Name</label>
                <input type="text" formControlName="name" class="form-control" id="name" placeholder="Enter budget name"
                    [ngClass]="{ 'is-invalid': editBudgetForm.controls['name'].errors }">
            </div>
            <div class="form-group">
                <label for="amountPerMember" i18n="@@amountPerMember">Amount per member</label>
                <div class="input-group">
                    <input class="form-control" type="number" min="0.00" step="0.01" formControlName="amountPerMember"
                        id="amountPerMember"
                        [ngClass]="{ 'is-invalid': editBudgetForm.controls['amountPerMember'].errors }">
                    <div class="input-group-append">
                        <span class="input-group-text">€</span>
                    </div>
                </div>
            </div>
            <div class="custom-control custom-checkbox">
                <input formControlName="canFinanceTime" type="checkbox" id="canFinanceTime"
                    class="custom-control-input" name="canFinanceTime"
                    [ngClass]="{ 'is-invalid': editBudgetForm.controls['canFinanceTime'].errors }" />
                <label for="canFinanceTime" class="custom-control-label">
                    <span i18n="@@canFinanceTime">This budget can finance time</span>
                </label>
            </div>
            <div *ngIf="this.editBudgetForm.controls['canFinanceTime'].value" class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="costOfDay" i18n="@@costOfDay">Cost of a day</label>
                        <div class="input-group">
                            <input class="form-control" type="number" min="0.00" step="0.01" formControlName="costOfDay"
                                id="costOfDay"
                                [ngClass]="{ 'is-invalid': editBudgetForm.controls['costOfDay'].errors }">
                            <div class="input-group-append">
                                <span class="input-group-text">€</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="costOfHour" i18n="@@costOfHour">Cost of an hour</label>
                        <div class="input-group">
                            <input class="form-control" type="number" min="0.00" step="0.01" formControlName="costOfHour"
                                id="costOfHour"
                                [ngClass]="{ 'is-invalid': editBudgetForm.controls['costOfHour'].errors }">
                            <div class="input-group-append">
                                <span class="input-group-text">€</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="startDate" i18n="@@startDate">Starting Date</label>
                        <input type="date" formControlName="startDate" class="form-control" id="startDate"
                            placeholder="Enter budget starting date"
                            [ngClass]="{ 'is-invalid': editBudgetForm.controls['startDate'].errors }">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="endDate" i18n="@@endDate">Ending Date</label>
                        <input type="date" formControlName="endDate" class="form-control" id="endDate"
                            placeholder="Enter budget ending date"
                            [ngClass]="{ 'is-invalid': editBudgetForm.controls['endDate'].errors }">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="rules" i18n="@@budgetsRules">Rules</label>
                <select class="custom-select" class="form-control" style="width: 100%;" formControlName="rules"
                    id="rules">
                    <option *ngFor="let item of rules | keyvalue" value="{{ item.key }}">{{ item.value.name }}</option>
                </select>
            </div>
        </form>
        <div *ngIf="selectedBudget.isDistributed" class="callout callout-info" i18n="@@budgetFieldDisabled">
            Some field have been disabled because budget is already distributed between organization members.
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="editBudgetModal.hide()" i18n="@@close">Close</button>
        <button *ngIf="selectedBudget.id > 0" type="button" class="btn btn-primary" (click)="saveBudget()"
            i18n="@@save">Save</button>
        <button *ngIf="selectedBudget.id <= 0" type="button" class="btn btn-primary" (click)="saveBudget()"
            i18n="@@create">Create</button>
    </div>
</ng-template>