<div class="row action-bar">
    <div class="col-md-9">
        <div class="form-horizontal">
            <button type="button" class="btn btn-default"
                [ngClass]="{ 'btn-success': refreshStatus === 'success', 'btn-danger': refreshStatus === 'error' }"
                (click)="refresh(projectsPager.currentPage)">
                <i class="fas fa-sync"></i>
                <span i18n="@@refresh">Refresh</span>
            </button>

            <div class="btn-group">
                <button type="button" class="btn btn-outline-primary"
                    [ngClass]="{ 'btn-primary': status === 'idea', 'btn-outline-primary': status !== 'idea'}"
                    routerLink="/projects" [queryParams]="{status: 'idea'}">
                    <i class="far fa-lightbulb"></i>
                    <span i18n="@@listProjectsStatusIdeas">Ideas</span>
                </button>
                <button type="button" class="btn btn-outline-primary"
                    [ngClass]="{ 'btn-primary': status === 'in_progress', 'btn-outline-primary': status !== 'in_progress'}"
                    routerLink="/projects" [queryParams]="{status: 'in_progress'}">
                    <i class="fa-solid fa-person-running"></i>
                    <span i18n="@@listProjectsStatusInProgress">In Progress</span>
                </button>
                <button type="button" class="btn"
                    [ngClass]="{ 'btn-primary': status === 'on_pause', 'btn-outline-primary': status !== 'on_pause'}"
                    routerLink="/projects" [queryParams]="{status: 'on_pause'}">
                    <i class="fa-solid fa-pause"></i>
                    <span i18n="@@listProjectsStatusOnPause">On Pause</span>
                </button>
                <button type="button" class="btn"
                    [ngClass]="{ 'btn-primary': status === 'finished', 'btn-outline-primary': status !== 'finished'}"
                    routerLink="/projects" [queryParams]="{status: 'finished'}">
                    <i class="fa-solid fa-check"></i>
                    <span i18n="@@listProjectsStatusFinished">Finished</span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="text-right">
            <label for="projectsLength" i18n="@@nbEntries">Nb entries</label>
            <select id="projectsLength" name="projectsLength" class="datatable-nb-entries form-control input-sm"
                [(ngModel)]="projectsLength" (ngModelChange)="refresh()">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body table-responsive p-0">
                <table class="table table-striped table-hover projects">
                    <thead>
                        <tr>
                            <th i18n="@@listProjectsTitle" style="width: 20%">
                                Project Name
                            </th>
                            <th i18n="@@listProjectsDescription">
                                Description
                            </th>
                            <th *ngIf="status == 'idea'" i18n="@@listProjectsUpVotes">
                                Up Votes
                            </th>
                            <th *ngIf="status == 'idea'" i18n="@@listProjectsDownVotes">
                                Down Votes
                            </th>
                            <th *ngIf="status != 'idea'" i18n="@@listProjectsFunding" style="width: 20%">
                                Founding
                            </th>
                            <th *ngIf="status != 'idea'" i18n="@@listProjectsTeamMembers">
                                Team Members
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="projectsPaged.length === 0">
                            <td colspan="4" class="text-center" i18n="@@noProjectFound">No project was found</td>
                        </tr>
                        <tr class="item-clickable" *ngFor="let item of projectsPaged"
                            routerLink="/projects/{{ item.id }}">
                            <td>
                                <a>
                                    {{ item.title }}
                                </a>
                                <br>
                                <small>
                                    <span i18n="@@createdAtInline">Created at </span> {{ item.createdAt |
                                    date:'dd/MM/yyyy'
                                    }}
                                </small>
                            </td>
                            <td>
                                {{ item.shortDescription }}
                            </td>
                            <td *ngIf="status == 'idea'">
                                <i class="fa fa-arrow-up"></i>
                                <span>{{ projectsScores.get(item.id)?.up }}</span>
                            </td>
                            <td *ngIf="status == 'idea'">
                                <i class="fa fa-arrow-down"></i>
                                <span>{{ projectsScores.get(item.id)?.down }}</span>
                            </td>
                            <td [ngClass]="{'missingBudget': projectsMissingBudget.get(item.id)}"
                                *ngIf="status != 'idea'">
                                {{ projectsTotalDonations.get(item.id)?.toFixed(2) }} € / {{
                                projectsDonationsRequired.get(item.id)?.toFixed(2) }} €
                            </td>
                            <td *ngIf="status != 'idea'">
                                <ul class="list-inline">
                                    <li class="list-inline-item" *ngFor="let userId of item.peopleGivingTimeRef">
                                        <img [src]="users.get(userId)?.avatarUrl"
                                            [ngClass]="{ 'hasLeftTheOrganization': users.get(userId)?.hasLeftTheOrganization}"
                                            alt="Avatar" class="table-avatar" />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-5">
        <div *ngIf="projectsPager.pages && projectsPager.pages.length" class="dataTables_info">
            <span i18n="@@pageShowing">Showing </span>
            <span>{{ projectsPager.startIndex + 1 }}</span>
            <span i18n="@@pageTo"> to </span>
            <span>{{ projectsPager.endIndex + 1 }}</span>
            <span i18n="@@pageOf"> of </span>
            <span>{{ projectsPager.totalItems }}</span>
            <span i18n="@@pageEntries"> entries</span>
        </div>
    </div>
    <div class="col-sm-7">
        <ul *ngIf="projectsPager.pages && projectsPager.pages.length" class="pagination justify-content-end">
            <li class="page-item" [ngClass]="{disabled:projectsPager.currentPage === 1}">
                <a class="page-link" (click)="refresh(1)">&#171;</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:projectsPager.currentPage === 1}">
                <a class="page-link" (click)="refresh(projectsPager.currentPage - 1)">&#8249;</a>
            </li>
            <li class="page-item" *ngFor="let page of projectsPager.pages"
                [ngClass]="{active:projectsPager.currentPage === page}">
                <a class="page-link" (click)="refresh(page)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:projectsPager.currentPage === projectsPager.totalPages}">
                <a class="page-link" (click)="refresh(projectsPager.currentPage + 1)">&#8250;</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:projectsPager.currentPage === projectsPager.totalPages}">
                <a class="page-link" (click)="refresh(projectsPager.totalPages)">&#187;</a>
            </li>
        </ul>
    </div>
</div>