<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title" i18n="@@organizations">Organizations</h3>
            </div>
            <div class="card-body">
                <div class="row action-bar">
                    <div class="col-6">
                        <button type="button" class="btn btn-default"
                            [ngClass]="{ 'btn-success': refreshStatus === 'success', 'btn-danger': refreshStatus === 'error' }"
                            (click)="refresh(pager.currentPage)"><i class="fas fa-sync"></i>
                            <span i18n="@@refresh">Refresh</span></button>
                        <button type="button" class="btn btn-default" routerLink="/organizations/new"><i
                                class="fa fa-plus"></i>
                            <span i18n="@@add">Add</span>
                        </button>
                    </div>
                    <div class="col-6">
                        <div class="dataTables_length text-right"><label for="pageSize" i18n="@@nbEntries">Nb
                                entries</label>
                            <select id="pageSize" name="pageSize" class="datatable-nb-entries form-control input-sm"
                                [(ngModel)]="pageSize" (ngModelChange)="refresh()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th i18n="@@name">Name</th>
                                    <th i18n="@@createdAt">Created at</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of pagedItems">
                                    <td>{{item.id}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.createdAt | date:'medium'}}</td>
                                    <td class="action-bar">
                                        <a class="text-muted item-clickable"
                                            [routerLink]="['/organizations/edit/', item.id]">
                                            <i class="fas fa-pencil-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div *ngIf="pager.pages && pager.pages.length" class="dataTables_info">
                            <span i18n="@@pageShowing">Showing </span>
                            <span>{{ pager.startIndex + 1 }}</span>
                            <span i18n="@@pageTo"> to </span>
                            <span>{{ pager.endIndex + 1 }}</span>
                            <span i18n="@@pageOf"> of </span>
                            <span>{{ pager.totalItems }}</span>
                            <span i18n="@@pageEntries"> entries</span>
                        </div>
                    </div>
                    <div class="col-7">
                        <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end">
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                <a class="page-link" (click)="refresh(1)">&#171;</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                <a class="page-link" (click)="refresh(pager.currentPage - 1)">&#8249;</a>
                            </li>
                            <li class="page-item" *ngFor="let page of pager.pages"
                                [ngClass]="{active:pager.currentPage === page}">
                                <a class="page-link" (click)="refresh(page)">{{page}}</a>
                            </li>
                            <li class="page-item next" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a class="page-link" (click)="refresh(pager.currentPage + 1)">&#8250;</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a class="page-link" (click)="refresh(pager.totalPages)">&#187;</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>