<div class="row">
    <div class="col-md-8">
        <div class="card card-default">
            <div class="card-header">
                <h3 class="card-title" i18n="@@description">Description</h3>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div id="long-description" class="card-body" [innerHTML]="project.longDescription">
            </div>
        </div>
        <div class="card card-default" *ngIf="project.status === 'IDEA'">
            <div class="card-header">
                <h3 class="card-title" i18n="@@viewProjectVotes">Votes</h3>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-right">
                        <button (click)="onSubmitVote(voteType.UP)"
                            [ngClass]="{ 'btn-success': vote.id > 0 && vote.type == voteType.UP, 'btn-outline-success': vote.id <= 0 || vote.type != voteType.UP}"
                            type="button" class="btn btn-outline-success btn-lg btn-flat">
                            <i class="fa fa-arrow-up"></i>
                            <span>{{ score.up }}</span>
                        </button>
                    </div>
                    <div class="col-4 text-center">
                        <span class="vote-score">{{ score.up - score.down }}</span>
                    </div>
                    <div class="col-4 text-left">
                        <button (click)="onSubmitVote(voteType.DOWN)"
                            [ngClass]="{ 'btn-danger': vote.id > 0 && vote.type == voteType.DOWN, 'btn-outline-danger': vote.id <= 0 || vote.type != voteType.DOWN}"
                            type="button" class="btn btn-lg btn-flat">
                            <i class="fa fa-arrow-down"></i>
                            <span>{{ score.down }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-default" *ngIf="project.status !== 'DRAFT' && project.status !== 'IDEA'">
            <div class="card-header">
                <h3 class="card-title" i18n="@@founding">Founding</h3>
                <div class="card-tools">
                    <a class="btn btn-tool btn-sm" (click)="openAddFundingModal(addFundintModalTemplate)"
                        *ngIf="leader.id === userLoggedIn.id && project.peopleGivingTimeRef.length >= 3">
                        <i class="fas fa-plus-circle"></i>
                    </a>
                    <a class="btn btn-tool btn-sm" (click)="refreshCampaigns()"
                        [ngClass]="{ 'text-success': campaignsSyncStatus === 'success'}">
                        <i class="fas fa-sync"></i>
                    </a>
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="card-body table-responsive p-0">
                <div *ngIf="campaigns.length === 0" class="text-center m-3">
                    <div i18n="@@noFoundingInProject">This project has no founding</div>
                </div>
                <table *ngIf="campaigns.length > 0" class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th i18n="@@status">Status</th>
                            <th i18n="@@foundingType">Funding type</th>
                            <th i18n="@@budget">Budget</th>
                            <th i18n="@@campaignListBegin">Starts</th>
                            <th i18n="@@campaignListEnd">Ends</th>
                            <th i18n="@@amount">Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let campaign of campaigns">
                            <td [ngSwitch]="campaign.status">
                                <span *ngSwitchCase="campaignStatus.IN_PROGRESS" class="badge bg-yellow">J - {{
                                    campaign.remainingDays }}</span>
                                <span *ngSwitchCase="campaignStatus.FAILED" class="badge bg-gray"
                                    i18n="@@cancelled">Cancelled</span>
                                <span *ngSwitchCase="campaignStatus.SUCCESSFUL" class="badge bg-green"
                                    i18n="@@completed">Completed</span>
                            </td>
                            <td i18n="@@campaign">Campaign</td>
                            <td><span *ngIf="campaignsBudgets.get(campaign.budget.id) !== undefined">{{
                                    campaignsBudgets.get(campaign.budget.id)?.name }}</span></td>
                            <td>{{ campaign.createdAt | date:'d MMM y' }}</td>
                            <td>{{ campaign.fundingDeadline | date:'d MMM y' }}</td>
                            <td class="text-center">
                                <span>{{ campaign.totalDonations.toFixed(2) }} € / {{ campaign.totalRequired.toFixed(2)
                                    }} €</span>
                            </td>
                            <td>
                                <a *ngIf="campaign.status === campaignStatus.IN_PROGRESS && campaign.totalDonations < campaign.donationsRequired"
                                    class="btn btn-block btn-default btn-sm"
                                    (click)="openContributingModal(contributeFinanciallyModalTemplate, campaign)">
                                    <i class="fas fa-hand-holding-usd">
                                    </i>
                                    <span i18n="@@contributeFinancially">Contribute financially</span>
                                </a>
                                <a *ngIf="campaign.status !== campaignStatus.FAILED"
                                    class="btn btn-block btn-default btn-sm"
                                    (click)="openDonationsModal(viewDonationsModalTemplate, campaign)">
                                    <i class="fas fa-search-dollar"></i>
                                    <span i18n="@@viewDonations">View Donations</span>
                                </a>
                                <a *ngIf="campaign.status === campaignStatus.IN_PROGRESS && userLoggedIn.id === project.leader.id"
                                    class="btn btn-block btn-default btn-sm"
                                    (click)="editFundingModal(addFundintModalTemplate, campaign.id)">
                                    <i class="fas fa-edit">
                                    </i>
                                    <span i18n="@@edit">Edit</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="campaignsSyncStatus === 'running'" class="overlay">
                <i class="fas fa-sync fa-spin"></i>
            </div>
        </div>
        <div class="card card-default" *ngIf="project.status !== 'DRAFT' && project.status !== 'IDEA'">
            <div class="card-header">
                <h3 class="card-title" i18n="@@news">News</h3>
                <div class="card-tools">
                    <a class="btn btn-tool btn-sm" routerLink="/projects/{{ project.id }}/news/new"
                        *ngIf="leader.id === userLoggedIn.id">
                        <i class="fas fa-plus-circle"></i>
                    </a>
                    <a class="btn btn-tool btn-sm" (click)="refreshNews()"
                        [ngClass]="{ 'text-success': newsSyncStatus === 'success'}">
                        <i class="fas fa-sync"></i>
                    </a>
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="post clearfix" *ngFor="let news of newsPaged">
                    <div class="user-block">
                        <img class="img-circle img-bordered-sm" [src]="getUser(news.author.id).avatarUrl"
                            alt="user image">
                        <span class="username">
                            <b>{{ getUser(news.author.id).getFullname() }}</b>
                            <span *ngIf="news.type =='ARTICLE'" i18n="@@publishedNews"> published the news </span>
                            <span *ngIf="news.type =='IDEA_PUBLISHED'" i18n="@@newsTitleIdeaPublished"> has an idea :
                            </span>
                            <span *ngIf="news.type =='PROJECT_PUBLISHED'" i18n="@@newsTitleProjectPublished"> launched
                                the
                                project </span>
                            <span>{{ news.title }}</span>
                        </span>

                        <span class="description">{{ news.createdAt | date:'medium' }}</span>
                    </div>
                    <div [innerHTML]="news.content"></div>
                </div>
            </div>
            <div *ngIf="newsSyncStatus === 'running'" class="overlay">
                <i class="fas fa-sync fa-spin"></i>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card card-default">
            <div class="card-header">
                <h3 class="card-title" i18n="@@informations">Informations</h3>
            </div>
            <div class="card-body">
                <dl class="dl-horizontal">
                    <dt *ngIf="project.status == projectStatus.IDEA" i18n="@@viewProjectCreator">Creator</dt>
                    <dt *ngIf="project.status != projectStatus.IDEA" i18n="@@viewProjectLeader">Project Leader</dt>
                    <dd *ngIf="project.ideaHasAnonymousCreator" i18n="@@viewProjectAnonymous">Anonymous</dd>
                    <dd *ngIf="!project.ideaHasAnonymousCreator">{{ leader.firstname }} {{ leader.lastname }}</dd>
                    <dt i18n="@@viewProjectCreatedAt">Created at</dt>
                    <dd>{{ project.createdAt | date:'medium' }}</dd>
                    <dt i18n="@@viewProjectStatus">Status</dt>
                    <dd [ngSwitch]="project.status">
                        <span *ngSwitchCase="projectStatus.DRAFT" i18n="@@viewProjectStatusDraft"
                            class="badge bg-gray">Draft</span>
                        <span *ngSwitchCase="projectStatus.IDEA" i18n="@@viewProjectStatusIdea"
                            class="badge bg-blue">Idea</span>
                        <span *ngSwitchCase="projectStatus.IN_PROGRESS" i18n="@@viewProjectStatusInProgress"
                            class="badge bg-yellow">In
                            Progress</span>
                        <span *ngSwitchCase="projectStatus.ON_PAUSE" i18n="@@viewProjectStatusOnPause"
                            class="badge bg-gray">On Pause</span>
                        <span *ngSwitchCase="projectStatus.FINISHED" i18n="@@viewProjectStatusFinished"
                            class="badge bg-green">Finished</span>
                    </dd>
                    <dt *ngIf="project.status === projectStatus.IN_PROGRESS || project.status === projectStatus.FINISHED"
                        i18n="@@peopleNeeded">People needed</dt>
                    <dd
                        *ngIf="project.status === projectStatus.IN_PROGRESS || project.status === projectStatus.FINISHED">
                        {{ members.length }} / {{ 1 + project.peopleRequired }}</dd>
                </dl>
                <div class="callout callout-info"
                    *ngIf="project.ideaHasLeaderCreator && project.status === projectStatus.IDEA">
                    <p><span i18n="@@atIdeaSubmission">At the idea submission, </span>{{ leader.firstname }} {{
                        leader.lastname }}<span i18n="@@wishedToLeadProject"> wished to lead the project.</span></p>
                </div>
            </div>
            <div class="card-footer text-center">
                <a class="btn btn-app bg-pink" routerLink="/projects/{{ id }}/edit"
                    *ngIf="leader.id === userLoggedIn.id || (!project.ideaHasLeaderCreator && project.status === projectStatus.IDEA)">
                    <i class="fas fa-edit"></i>
                    <span i18n="@@viewProjectEdit">Edit</span>
                </a>
                <a class="btn btn-app bg-yellow" (click)="openModal(publishIdeaModalRef)"
                    *ngIf="(leader.id === userLoggedIn.id || !project.ideaHasLeaderCreator) && project.status === projectStatus.DRAFT">
                    <i class="fas fa-lightbulb"></i>
                    <span i18n="@@viewProjectPublishAsIdea">Publish as Idea</span>
                </a>
                <a class="btn btn-app bg-purple" (click)="openModal(startProjectModalRef)"
                    *ngIf="(leader.id === userLoggedIn.id || !project.ideaHasLeaderCreator) && project.status === projectStatus.DRAFT">
                    <i class="fas fa-rocket"></i>
                    <span i18n="@@viewProjectPublishAsProject">Publish as Project</span>
                </a>
                <a class="btn btn-app bg-purple" (click)="openModal(startProjectModalRef)"
                    *ngIf="(leader.id === userLoggedIn.id || !project.ideaHasLeaderCreator) && project.status === projectStatus.IDEA">
                    <i class="fas fa-rocket"></i>
                    <span i18n="@@viewProjectStartProject">Start the project</span>
                </a>
                <a class="btn btn-app bg-purple" (click)="reopen()"
                    *ngIf="leader.id === userLoggedIn.id && (project.status === projectStatus.ON_PAUSE || project.status === projectStatus.FINISHED)">
                    <i class="fas fa-undo"></i>
                    <span i18n="@@viewProjectReopen">Reopen</span>
                </a>
                <a class="btn btn-app bg-purple" (click)="pause()"
                    *ngIf="leader.id === userLoggedIn.id && project.status === projectStatus.IN_PROGRESS">
                    <i class="fas fa-pause"></i>
                    <span i18n="@@viewProjectPause">Pause</span>
                </a>
                <a class="btn btn-app bg-green" (click)="finish()"
                    *ngIf="leader.id === userLoggedIn.id && project.status !== projectStatus.FINISHED">
                    <i class="fas fa-check"></i>
                    <span i18n="@@viewProjectClose">Close</span>
                </a>
                <a class="btn btn-app bg-blue" (click)="join()"
                    *ngIf="isUserInTeam === false && leader.id !== userLoggedIn.id && project.status === projectStatus.IN_PROGRESS">
                    <i class="fas fa-user-plus"></i>
                    <span i18n="@@viewProjectJoinTeam">Join the team</span>
                </a>
                <a class="btn btn-app bg-indigo" (click)="join()"
                    *ngIf="isUserInTeam === true && leader.id !== userLoggedIn.id && project.status === projectStatus.IN_PROGRESS">
                    <i class="fas fa-user-minus"></i>
                    <span i18n="@@viewProjectLeaveTeam">Leave the team</span>
                </a>
            </div>
        </div>
        <div class="card" *ngIf="project.status !== 'DRAFT' && project.status !== 'IDEA'">
            <div class="card-header">
                <h3 class="card-title" i18n="@@team">Team</h3>
                <div class="card-tools">
                    <a class="btn btn-tool btn-sm" (click)="refreshMembers()"
                        [ngClass]="{ 'text-success': membersSyncStatus === 'success'}">
                        <i class="fas fa-sync"></i>
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <ul class="users-list clearfix">
                    <li *ngFor="let user of members">
                        <img [src]="user.avatarUrl" alt="User Image"
                            [ngClass]="{ 'hasLeftTheOrganization': user.hasLeftTheOrganization}"
                            style="max-height: 90px;">
                        <div class="users-list-name"><span>{{ user.firstname }} {{ user.lastname }}<br /></span><span
                                *ngIf="user.hasLeftTheOrganization">Left</span></div>
                    </li>
                </ul>
            </div>
            <div *ngIf="membersSyncStatus === 'running'" class="overlay">
                <i class="fas fa-sync fa-spin"></i>
            </div>
        </div>
    </div>
</div>

<ng-template #publishIdeaModalRef>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@publishIdea">Publish the idea</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="publishIdeaModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="publishIdeaForm">
            <div class="form-group">
                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" class="custom-control-input" id="ideaHasAnonymousCreator"
                        formControlName="ideaHasAnonymousCreator">
                    <label class="custom-control-label" for="ideaHasAnonymousCreator"
                        i18n="@@publishIdeaAnonymously">Publish this idea anonymously</label>
                </div>
            </div>
            <div class="form-group" *ngIf="!publishIdeaForm.controls['ideaHasAnonymousCreator'].value">
                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" class="custom-control-input" id="ideaHasLeaderCreator"
                        formControlName="ideaHasLeaderCreator">
                    <label class="custom-control-label" for="ideaHasLeaderCreator" i18n="@@userWhantToLeadRealOfIdea">I
                        want
                        to lead the realization of the idea</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="publishIdeaSubmit()" i18n="@@submit">Submit</button>
    </div>
</ng-template>

<ng-template #startProjectModalRef>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@viewProjectStartProject">Start the project</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="viewRulesModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="startProjectForm">
            <div class="form-group">
                <label for="peopleRequired" i18n="@@peopleNeededInAdditionOfProjectLeader">People needed (in
                    addition of the project leader)</label>
                <input formControlName="peopleRequired" type="number" class="form-control" id="peopleRequired" min="2"
                    [ngClass]="{ 'is-invalid': this.startProjectForm.controls['peopleRequired'].errors }" />
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="startProjectSubmit()" i18n="@@submit">Submit</button>
    </div>
</ng-template>

<ng-template #contributeFinanciallyModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@contributeFinancially">Contribute financially</h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="contributeFinanciallyModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 i18n="@@campaignGoals" style="text-align: center;">Campaign Goals</h5>
        <table class="table table-sm">
            <tbody>
                <tr>
                    <td i18n="@@contributeFinanciallyExpenses">Expenses : </td>
                    <td class="text-right">{{ selectedCampaign.donationsRequired.toFixed(2) }} €</td>
                </tr>
                <tr
                    *ngIf="getBudget(account.budget.id).canFinanceTime && (selectedCampaign.daysRequired > 0 || selectedCampaign.hoursRequired > 0)">
                    <td>
                        <span>{{ selectedCampaign.daysRequired }}</span>
                        <span i18n="@@contributeFinanciallyDaysAbbr">d </span>
                        <span>{{ selectedCampaign.hoursRequired }}</span>
                        <span i18n="@@contributeFinanciallyHoursAbbr">h </span>
                        <span i18n="@@contributeFinanciallyOfWork">of work</span>
                        <span> : </span>
                    </td>
                    <td class="text-right">{{ (selectedCampaign.daysRequired * getBudget(account.budget.id).costOfDay +
                        selectedCampaign.hoursRequired * getBudget(account.budget.id).costOfHour).toFixed(2) }}
                        €</td>
                </tr>
                <tr class="bold">
                    <td i18n="@@contributeFinanciallyTotal">Total : </td>
                    <td class="text-right">{{ selectedCampaign.totalRequired.toFixed(2) }} €</td>
                </tr>
                <tr class="bold">
                    <td i18n="@@contributeFinanciallyAlreadyCollected">Already collected : </td>
                    <td class="text-right">{{ selectedCampaign.totalDonations.toFixed(2) }} €</td>
                </tr>
            </tbody>
        </table>
        <h5 i18n="@@myContribution" style="text-align: center;">My contribution</h5>
        <form [formGroup]="donationForm">
            <div class="form-group">
                <label for="amount" i18n="@@budget">Budget</label>
                <select class="form-control select-border" style="width: 100%;" disabled>
                    <option>{{ campaignsBudgets.get(account.budget.id)?.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="amount" i18n="@@amountInEuros">Amount (in €)</label>
                <input type="number" formControlName="amount" class="form-control" id="amount" min="0.00" step="0.01"
                    max="{{ (min(account.amount, selectedCampaign.totalRequired - selectedCampaign.totalDonations)).toFixed(2) }}"
                    [ngClass]="{ 'is-invalid': donationForm.controls['amount'].errors }" />
            </div>
            <div class="callout callout-info">
                <span i18n="@@youWillHave">You will have </span>
                <b>{{ (account.amount -
                    donationForm.controls['amount'].value).toFixed(2) }} €</b>
                <span i18n="@@youWillHaveEnd"> left to finance other projects.</span>
            </div>
            <div class="callout callout-warning">
                <p i18n="@@yourContributionMayTakeTime">Your contribution may take a few times before beeing
                    taken into account.</p>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="onSubmitDonation()" i18n="@@submit">Submit</button>
    </div>
</ng-template>

<ng-template #addFundintModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@foundingCampaign">Funding campaign</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addFundingModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formFunding">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="amount" i18n="@@budget">Budget</label>
                                <select class="form-control select-border" style="width: 100%;"
                                    formControlName="budget">
                                    <option *ngFor="let item of budgets; index as i" value="{{ i }}">{{
                                        item.name }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="donationsRequired" i18n="@@moneyNeededInEuros">Money needed (in
                                    €)</label>
                                <div class="input-group">
                                    <input formControlName="donationsRequired" type="number" class="form-control"
                                        id="donationsRequired" min="{{ minDonations }}" step="0.01"
                                        [ngClass]="{ 'is-invalid': fFunding['donationsRequired'].errors }" />
                                    <div class="input-group-append">
                                        <span class="input-group-text">€</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.budgets.length > 0 && fFunding['budget'].value !== undefined && budgets[fFunding['budget'].value].canFinanceTime"
                                class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label for="daysRequired" i18n="@@addFundingDaysRequired">Days needed</label>
                                        <div class="input-group">
                                            <input formControlName="daysRequired" type="number" class="form-control"
                                                id="daysRequired" min="0" step="1"
                                                [ngClass]="{ 'is-invalid': fFunding['daysRequired'].errors }" />
                                            <div class="input-group-append">
                                                <span class="input-group-text" i18n="@@days">days</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="hoursRequired" i18n="@@addFundingHoursRequired">Hours needed</label>
                                        <div class="input-group">
                                            <input formControlName="hoursRequired" type="number" class="form-control"
                                                id="hoursRequired" min="0" step="1"
                                                [ngClass]="{ 'is-invalid': fFunding['hoursRequired'].errors }" />
                                            <div class="input-group-append">
                                                <span class="input-group-text" i18n="@@hours">hour(s)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.budgets.length > 0 && fFunding['budget'].value !== undefined && budgets[fFunding['budget'].value].canFinanceTime"
                                class="form-group">
                                <label for="donationsRequired" i18n="@@addFundingTotalRequired">Total needed (in
                                    €)</label>
                                <div class="input-group">
                                    <input type="number" class="form-control"
                                        [value]="fFunding['donationsRequired'].value + budgets[fFunding['budget'].value].costOfDay * fFunding['daysRequired'].value + budgets[fFunding['budget'].value].costOfHour * fFunding['hoursRequired'].value"
                                        disabled />
                                    <div class="input-group-append">
                                        <span class="input-group-text">€</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="fundingDeadline" i18n="@@fundingDeadline" style="display: block;">Funding
                                    Deadline</label>
                                <div class="input-group date" id="fundingDeadline">
                                    <input type="date" class="form-control datetimepicker-input"
                                        formControlName="fundingDeadline" min="{{ now | date:'yyyy-MM-dd' }}"
                                        max="{{ nowPlus3Months | date:'yyyy-MM-dd' }}"
                                        [ngClass]="{ 'is-invalid': fFunding['fundingDeadline'].errors }" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div *ngIf="this.budgets.length > 0 && fFunding['budget'].value !== undefined"
                                class="custom-control custom-checkbox">
                                <input formControlName="rulesCompliant" type="checkbox" id="rulesCompliant"
                                    class="custom-control-input" name="rulesCompliant"
                                    [ngClass]="{ 'is-invalid': fFunding['rulesCompliant'].errors }" />
                                <label for="rulesCompliant" class="custom-control-label">
                                    <span i18n="@@thisProjectIsRuleCompliant">This project is compliant with
                                        the rules of </span>
                                </label>
                                <br />
                                <span class="item-clickable" style="margin-left: 0.2em"
                                    (click)="onViewTermsOfUse(viewRulesModalTemplate)"><b> <u>{{
                                            budgets[fFunding['budget'].value].name }}</u></b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="text-center">
            <button [disabled]="submittingFunding" type="button" class="btn btn-primary" (click)="onSubmitFunding()"
                i18n="@@save">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #viewRulesModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ rules.name }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="viewRulesModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="rules.value">
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="viewRulesModal.hide()" i18n="@@close">Close</button>
    </div>
</ng-template>

<ng-template #viewDonationsModalTemplate>
    <div *ngIf="donationsSyncStatus === 'running'" class="overlay">
        <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-header">
        <h4 class="modal-title" i18n="@@donations">Donations</h4>
        <div class="text-right">
            <button type="button" class="close" aria-label="Close" (click)="viewDonationsModal.hide()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-default"
                    [ngClass]="{ 'btn-success': donationsSyncStatus === 'success', 'btn-danger': donationsSyncStatus === 'error' }"
                    (click)="refreshDonations(viewDonationsSelectedCampaign.id, 1)">
                    <i class="fas fa-sync"></i>
                    <span i18n="@@refresh">Refresh</span>
                </button>
            </div>
            <div class="col-md-6 text-right action-bar">
                <label for="donationsLength" i18n="@@nbEntries">Nb entries</label>
                <select id="donationsLength" name="donationsLength" class="datatable-nb-entries form-control input-sm"
                    [(ngModel)]="donationsLength"
                    (ngModelChange)="refreshDonations(viewDonationsSelectedCampaign.id, 1)">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th i18n="@@date">Date</th>
                    <th i18n="@@contributor">Contributor</th>
                    <th i18n="@@amount">Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let donation of donationsPaged">
                    <td>{{ donation.createdAt | date:'medium' }}</td>
                    <td>{{ getContributorFromAccountId(donation.account.id).firstname }} {{
                        getContributorFromAccountId(donation.account.id).lastname }}</td>
                    <td>{{ donation.amount.toFixed(2) }} €</td>
                    <td>
                        <a *ngIf="getContributorFromAccountId(donation.account.id).id === this.userLoggedIn.id && viewDonationsSelectedCampaign.status === campaignStatus.IN_PROGRESS"
                            class="text-muted item-clickable"
                            [ngClass]="{ 'text-danger': deleteDonationsStatus[donation.id] === 'error' }"
                            (click)="deleteDonations(donation)">
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <div class="row" style="width: 100%;">
            <div class="col-5">
                <div *ngIf="donationsPager.pages && donationsPager.pages.length" class="dataTables_info">
                    <span i18n="@@pageShowing">Showing </span>
                    <span>{{ donationsPager.startIndex + 1 }}</span>
                    <span i18n="@@pageTo"> to </span>
                    <span>{{ donationsPager.endIndex + 1 }}</span>
                    <span i18n="@@pageOf"> of </span>
                    <span>{{ donationsPager.totalItems }}</span>
                    <span i18n="@@pageEntries"> entries</span>
                </div>
            </div>
            <div class="col-7">
                <ul *ngIf="donationsPager.pages && donationsPager.pages.length" class="pagination justify-content-end">
                    <li class="page-item" [ngClass]="{disabled:donationsPager.currentPage === 1}">
                        <a class="page-link" (click)="refreshDonations(viewDonationsSelectedCampaign.id, 1)">&#171;</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:donationsPager.currentPage === 1}">
                        <a class="page-link"
                            (click)="refreshDonations(viewDonationsSelectedCampaign.id, donationsPager.currentPage - 1)">&#8249;</a>
                    </li>
                    <li class="page-item" *ngFor="let page of donationsPager.pages"
                        [ngClass]="{active:donationsPager.currentPage === page}">
                        <a class="page-link"
                            (click)="refreshDonations(viewDonationsSelectedCampaign.id, page)">{{page}}</a>
                    </li>
                    <li class="page-item"
                        [ngClass]="{disabled:donationsPager.currentPage === donationsPager.totalPages}">
                        <a class="page-link"
                            (click)="refreshDonations(viewDonationsSelectedCampaign.id, donationsPager.currentPage + 1)">&#8250;</a>
                    </li>
                    <li class="page-item"
                        [ngClass]="{disabled:donationsPager.currentPage === donationsPager.totalPages}">
                        <a class="page-link"
                            (click)="refreshDonations(viewDonationsSelectedCampaign.id, donationsPager.totalPages)">&#187;</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>

<div class="toasts-top-right fixed" *ngIf="showConfirmContributingToast">
    <div class="toast bg-success fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header"><strong class="mr-auto">Merci</strong><button
                data-dismiss="toast" type="button" class="ml-2 mb-1 close" aria-label="Close"><span
                    aria-hidden="true">×</span></button></div>
        <div class="toast-body">Votre contribution a été prise en compte. Patientez et rafraîchissez la page.</div>
    </div>
</div>

<div class="toasts-top-right fixed" *ngIf="showConfirmDeleteDonationToast">
    <div class="toast bg-success fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header"><strong class="mr-auto">Merci</strong><button
                data-dismiss="toast" type="button" class="ml-2 mb-1 close" aria-label="Close"><span
                    aria-hidden="true">×</span></button></div>
        <div class="toast-body">Votre contribution va être supprimée. Patientez et rafraîchissez la page.</div>
    </div>
</div>