<div class="timeline" infiniteScroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="50" [fromRoot]="true"
    (scrolled)="onScroll()">
    <div *ngFor="let item of dates">
        <div class="time-label">
            <span class="bg-red">{{ item }}</span>
        </div>
        <div *ngFor="let news of newsByDate.get(item)">
            <i *ngIf="news.type === 'ARTICLE'" class="news-icon fas fa-newspaper bg-blue"></i>
            <i *ngIf="news.type === 'IDEA_PUBLISHED'" class="news-icon fas fa-lightbulb bg-yellow"></i>
            <i *ngIf="news.type === 'PROJECT_PUBLISHED'" class="news-icon fas fa-rocket bg-indigo"></i>

            <div class="timeline-item">
                <span class="time"><i class="fas fa-clock"></i> {{ news.createdAt | date:'medium' }}</span>
                <h3 class="timeline-header">
                    <b>{{ getUser(news.author.id).getFullname() }}</b>

                    <span *ngIf="news.type =='SYSTEM'" i18n="@@publishedNewsSystem"> published the system
                        message
                    </span>
                    <span *ngIf="news.type =='ARTICLE'" i18n="@@publishedNews"> published the news </span>
                    <span *ngIf="news.type =='IDEA_PUBLISHED'" i18n="@@newsTitleIdeaPublished"> has an idea : </span>
                    <span *ngIf="news.type =='PROJECT_PUBLISHED'" i18n="@@newsTitleProjectPublished"> launched the
                        project </span>
                    <span *ngIf="news.type =='CAMPAIGN_STARTED'" i18n="@@newsTitleCampaignStarted"> launched a funding
                        campaign for </span>

                    <a *ngIf="news.project.id > 0" routerLink="/projects/{{ news.project.id }}">
                        <b>{{ news.title }}</b>
                    </a>

                    <div class="newsTitle" *ngIf="news.type =='ARTICLE'">
                        <span *ngIf="news.project.id > 0" i18n="@@newsTitleProject"> in the project </span>
                        <b *ngIf="news.project.id > 0 && projects.get(news.project.id) !== undefined">{{
                            getProject(news.project.id).title }}</b>
                    </div>

                </h3>
                <div *ngIf="news.content != null && news.content != ''" class="timeline-body"
                    [innerHTML]="news.content"></div>
                <div *ngIf="news.type =='ARTICLE' && news.author.id === userLoggedIn.id"
                    class="timeline-footer action-bar">
                    <a *ngIf="news.type =='ARTICLE' && news.author.id === userLoggedIn.id"
                        class="btn btn-warning btn-sm" routerLink="/news/{{ news.id }}/edit">
                        <i class="fas fa-pen"></i>
                        <span i18n="@@editNews">Edit News</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div>
        <i class="news-icon fas fa-clock bg-gray"></i>
    </div>
</div>