<div class="wrapper">
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
        </div>

        <!-- Main content -->
        <div class="content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->

    <footer class="main-footer">
        <b>Les Projets Cagnottes</b> is an open-source app made by a few volunteers. Please, feel free to <b><a href="https://les-projets-cagnottes.github.io/contribute.html">discover how to help us 😊</a></b>
    </footer>

</div>
