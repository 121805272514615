<div class="row action-bar" [formGroup]="selectBudgetForm">
    <div class="col-md-2">
        <div class="form-group">
            <label for="amount" i18n="@@budget">Budget</label>
            <select class="form-control" style="width: 100%;" formControlName="budget">
                <option *ngFor="let budget of budgets; index as i" value="{{ budget.id }}">{{ budget.name }}</option>
            </select>
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label for="amount" i18n="@@reportRulesLabel">Rules</label>
            <button type="button" class="btn btn-block btn-default" (click)="onViewTermsOfUse(viewRulesModalTemplate)" i18n="@@reportRulesButton">View rules</button>
        </div>
    </div>
    <div class="col-md-8">
        <div id="budgetUsage" class="progress-group">
            <span class="progress-text" i18n="@@usageOverview">Usage overview</span>
            <div class="progress" title="{{ budgetUsage }}">
                <div class="progress-bar progress-bar-striped" [ngStyle]="{'width': budgetUsage }">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title" i18n="@@campaigns">Campaigns</h3>
                <div class="card-tools">
                    <a class="btn btn-tool btn-sm" (click)="refreshCampaigns(campaignPager.currentPage)"
                        [ngClass]="{ 'text-success': campaignsSyncStatus === 'success'}">
                        <i class="fas fa-sync"></i>
                    </a>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
                <table class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th i18n="@@reportTitle">Title</th>
                            <th i18n="@@reportCreatedAt">Created at</th>
                            <th i18n="@@reportDescription">Description</th>
                            <th i18n="@@reportTeamMembers">Team Members</th>
                            <th i18n="@@reportDonations">Donations</th>
                            <th i18n="@@reportDonationsRequired">Donations required</th>
                            <th i18n="@@reportStatus">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let campaign of pagedCampaigns">
                            <td><a *ngIf="projects.get(campaign.project.id) !== undefined"
                                    [routerLink]="['/projects/', campaign.project.id]">{{
                                    getProject(campaign.project.id).title }}</a></td>
                            <td>{{ campaign.createdAt |date:'medium' }}</td>
                            <td><span *ngIf="projects.get(campaign.project.id) !== undefined">{{
                                    getProject(campaign.project.id).shortDescription }}</span></td>
                            <td><span *ngIf="projects.get(campaign.project.id) !== undefined">{{
                                    getProject(campaign.project.id).peopleGivingTimeRef.length }} / 3</span></td>
                            <td>{{ campaign.totalDonations.toFixed(2) }} €</td>
                            <td>{{ campaign.totalRequired.toFixed(2) }} €</td>
                            <td>
                                <span *ngIf="campaign.status === 'IN_PROGRESS'" class="badge bg-yellow"
                                    i18n="@@reportStatusInProgress">In Progress</span>
                                <span *ngIf="campaign.status === 'FAILED'" class="badge bg-gray"
                                    i18n="@@reportStatusFailed">Failed</span>
                                <span *ngIf="campaign.status === 'SUCCESSFUL'" class="badge bg-green"
                                    i18n="@@reportStatusSuccessful">Successful</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="campaignsSyncStatus === 'running'" class="overlay">
                <i class="fas fa-sync fa-spin"></i>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <div class="row">
                    <div class="col-5">
                        <div *ngIf="campaignPager.pages && campaignPager.pages.length" class="dataTables_info">
                            <span i18n="@@pageShowing">Showing </span>
                            <span>{{ campaignPager.startIndex + 1 }}</span>
                            <span i18n="@@pageTo"> to </span>
                            <span>{{ campaignPager.endIndex + 1 }}</span>
                            <span i18n="@@pageOf"> of </span>
                            <span>{{ campaignPager.totalItems }}</span>
                            <span i18n="@@pageEntries"> entries</span>
                        </div>
                    </div>
                    <div class="col-7">
                        <ul *ngIf="campaignPager.pages && campaignPager.pages.length"
                            class="pagination justify-content-end">
                            <li class="page-item" [ngClass]="{disabled:campaignPager.currentPage === 1}">
                                <a class="page-link" (click)="refreshCampaigns(1)">&#171;</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:campaignPager.currentPage === 1}">
                                <a class="page-link"
                                    (click)="refreshCampaigns(campaignPager.currentPage - 1)">&#8249;</a>
                            </li>
                            <li class="page-item" *ngFor="let page of campaignPager.pages"
                                [ngClass]="{active:campaignPager.currentPage === page}">
                                <a class="page-link" (click)="refreshCampaigns(page)">{{page}}</a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:campaignPager.currentPage === campaignPager.totalPages}">
                                <a class="page-link"
                                    (click)="refreshCampaigns(campaignPager.currentPage + 1)">&#8250;</a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:campaignPager.currentPage === campaignPager.totalPages}">
                                <a class="page-link" (click)="refreshCampaigns(campaignPager.totalPages)">&#187;</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title" i18n="@@reportAccountsState">Accounts State</h3>
                <div class="card-tools">
                    <a class="btn btn-tool btn-sm" (click)="refreshAccounts(accountsPager.currentPage)"
                        [ngClass]="{ 'text-success': accountsSyncStatus === 'success'}">
                        <i class="fas fa-sync"></i>
                    </a>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-2 col-lg-3 col-md-4 col-xs-6" *ngFor="let account of pagedAccounts">
                        <div class="info-box">
                            <span class="info-box-icon"><img [src]="account.owner.avatarUrl" /></span>
                            <div class="info-box-content">
                                <span class="info-box-text">{{ account.owner.firstname }} {{ account.owner.lastname
                                    }}</span>
                                <div class="progress">
                                    <div class="progress-bar bg-yellow"
                                        [ngStyle]="{'width': account.usage }"></div>
                                </div>
                                <span class="progress-description">
                                    {{ account.amount }} € / {{ account.initialAmount }} €
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="accountsSyncStatus === 'running'" class="overlay">
                    <i class="fas fa-sync fa-spin"></i>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-5">
                <div *ngIf="accountsPager.pages && accountsPager.pages.length" class="dataTables_info">
                    <span i18n="@@pageShowing">Showing </span>
                    <span>{{ accountsPager.startIndex + 1 }}</span>
                    <span i18n="@@pageTo"> to </span>
                    <span>{{ accountsPager.endIndex + 1 }}</span>
                    <span i18n="@@pageOf"> of </span>
                    <span>{{ accountsPager.totalItems }}</span>
                    <span i18n="@@pageEntries"> entries</span>
                </div>
            </div>
            <div class="col-md-7">
                <ul *ngIf="accountsPager.pages && accountsPager.pages.length"
                    class="pagination justify-content-end">
                    <li class="page-item" [ngClass]="{disabled:accountsPager.currentPage === 1}">
                        <a class="page-link" (click)="refreshAccounts(1)">&#171;</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:accountsPager.currentPage === 1}">
                        <a class="page-link"
                            (click)="refreshAccounts(accountsPager.currentPage - 1)">&#8249;</a>
                    </li>
                    <li class="page-item" *ngFor="let page of accountsPager.pages"
                        [ngClass]="{active:accountsPager.currentPage === page}">
                        <a class="page-link" (click)="refreshAccounts(page)">{{page}}</a>
                    </li>
                    <li class="page-item"
                        [ngClass]="{disabled:accountsPager.currentPage === accountsPager.totalPages}">
                        <a class="page-link"
                            (click)="refreshAccounts(accountsPager.currentPage + 1)">&#8250;</a>
                    </li>
                    <li class="page-item"
                        [ngClass]="{disabled:accountsPager.currentPage === accountsPager.totalPages}">
                        <a class="page-link" (click)="refreshAccounts(accountsPager.totalPages)">&#187;</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #viewRulesModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ rules.name }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="viewRulesModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="rules.value">
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="viewRulesModal.hide()" i18n="@@close">Close</button>
    </div>
</ng-template>