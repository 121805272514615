<aside class="main-sidebar sidebar-dark-primary elevation-4">

  <!-- Logo -->
  <a href="" class="brand-link">
    <img src="assets/images/icon.png" alt="Platform Logo" class="brand-image img-circle elevation-3"
      style="opacity: .8">
    <span class="brand-text font-weight-light">Les Projets Cagnottes</span>
  </a>

  <div class="sidebar">

    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img [src]="currentOrganization.logoUrl" class="img-circle elevation-2" alt="Organization Image">
      </div>
      <div class="info">
        <a (click)="openChangeCurrentOrgModal(changeCurrentOrg)" class="d-block item-clickable">
          {{ currentOrganization.name }}</a>
      </div>
    </div>

    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img [src]="currentUser.avatarUrl" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info">
        <a routerLink="/profile" class="d-block">{{ currentUser.firstname }} {{ currentUser.lastname }}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav id="main-nav" class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-header" i18n="@@navProjects">PROJECTS</li>
        <li class="nav-item">
          <a routerLink="/projects/new" class="nav-link" [class]="{'active': navService.getPath() == '/projects/new'}">
            <i class="nav-icon far fa-paper-plane"></i>
            <p i18n="@@iHaveAnIdea">I have an idea</p>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/projects" class="nav-link" [class]="{'active': navService.getPath() == '/projects' || navService.getPath().startsWith('/projects?')}">
            <i class="nav-icon fas fa-book"></i>
            <span i18n="@@discoverProjects">Discover Projects</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/news" class="nav-link" [class]="{'active': navService.getPath() == '/news'}">
            <i class="nav-icon fas fa-newspaper"></i>
            <p i18n="@@newsfeed">
              Newsfeed
            </p>
          </a>
        </li>
        <li class="nav-header" i18n="@@navBudgets">BUDGETS</li>
        <li class="nav-item">
          <a routerLink="/report" class="nav-link" [class]="{'active': navService.getPath() == '/report'}">
            <i class="nav-icon fas fa-clipboard"></i>
            <p i18n="@@reporting">Reporting</p>
          </a>
        </li>
        <li *ngIf="isSponsor()" class="nav-item">
          <a routerLink="/budgets" class="nav-link" [class]="{'active': navService.getPath() == '/budgets'}">
            <i class="nav-icon fas fa-money-bill"></i>
            <p i18n="@@manageBudget">Manage budgets</p>
          </a>
        </li>
        <li *ngIf="isAdmin" class="nav-header" i18n="@@navAdministration">
          ADMINISTRATION</li>
        <li *ngIf="isAdmin" class="nav-item">
          <a routerLink="/users" class="nav-link" [class]="{'active': navService.getPath() == '/users'}">
            <i class="nav-icon fa fa-user"></i>
            <p i18n="@@users">Users</p>
          </a>
        </li>
        <li *ngIf="isAdmin" class="nav-item">
          <a routerLink="/organizations" class="nav-link" [class]="{'active': navService.getPath() == '/organizations'}">
            <i class="nav-icon fa fa-users"></i>
            <p i18n="@@organizations">Organizations</p>
          </a>
        </li>
        <li class="nav-header" i18n="@@navDocumentation">
          DOCUMENTATION</li>
        <li class="nav-item">
          <a routerLink="/doc/getting-started" class="nav-link" [class]="{'active': navService.getPath() == '/doc/getting-started'}">
            <i class="nav-icon fa fa-book"></i>
            <p i18n="@@gettingStarted">Getting Started</p>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/about" class="nav-link" [class]="{'active': navService.getPath() == '/about'}">
            <i class="nav-icon fas fa-code-branch"></i>
            <p i18n="@@about">About</p>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</aside>

<ng-template #changeCurrentOrg>
  <div class="modal-header">
      <h4 class="modal-title" i18n="@@myOrganizations">My organizations</h4>
      <button type="button" class="close" (click)="closeChangeCurrentOrgModal()">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body">
      <table class="table table-striped table-valign-middle">
          <thead>
              <tr>
                  <th i18n="@@organization">Organization</th>
                  <th i18n="@@members">Members</th>
                  <th style="width: 5%"></th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of userOrganizations">
                  <td>
                      <img [src]="item.logoUrl" class="img-circle img-size-32 mr-2" alt="Organization Image">
                      <span>{{ item.name }}</span>
                  </td>
                  <td>{{ item.membersRef.length }}</td>
                  <td class="action-bar">
                      <a *ngIf="currentOrganization.id !== item.id" class="item-clickable"
                          (click)="setCurrentOrganization(item)">
                          <i class="fas fa-arrow-right"></i>
                      </a>
                      <a *ngIf="isManager(item) || isOwner(item)" class="item-clickable"
                          (click)="editOrganization(item)">
                          <i class="fas fa-pencil-alt"></i>
                      </a>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</ng-template>