export class Pager {
    totalItems = 0;
    currentPage = 1;
    pageSize = 0;
    totalPages = 1;
    startPage = 0;
    endPage = 1;
    startIndex = 0;
    endIndex = 0;
    pages: number[] = [];
}
