<div class="login-page">

    <div class="login-box">
        <div class="card card-outline card-yellow">
            <div class="card-header text-center">
                <a class="h1">
                    <b>Les Projets Cagnottes</b>
                </a>
            </div>
            <div class="card-body">
                <p i18n="@@signInToStart" class="login-box-msg">Sign in to start your session
                </p>

                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="input-group mb-3">
                        <input type="email" formControlName="email" class="form-control" placeholder="Email"
                            i18n-placeholder="@@email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" formControlName="password" class="form-control" placeholder="Password"
                            i18n-placeholder="@@password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button [disabled]="loading" class="btn btn-primary btn-block" i18n="@@signIn"
                                (click)="onSubmit()">Sign In</button>
                        </div>
                    </div>
                </form>
                <div class="social-auth-links text-center mt-2 mb-3">
                    <a *ngIf="slackEnabled"
                        href="https://slack.com/oauth/v2/authorize?client_id={{ slackClientId }}&user_scope=team:read,users:read,users:read.email&redirect_uri={{ redirectUrlSlackOAuth }}"
                        class="btn btn-block bg-maroon">
                        <i class="fab fa-slack"></i>
                        <span i18n="@@signInUsingSlack">Sign in using Slack</span>
                    </a>
                    <a *ngIf="microsoftEnabled"
                        href="https://login.microsoftonline.com/{{ microsoftTenantId }}/oauth2/v2.0/authorize?scope=openid+profile+offline_access+&response_type=code&client_id={{ microsoftClientId }}&redirect_uri={{ redirectUrlMSOAuth }}"
                        class="btn btn-block btn-warning">
                        <i class="fab fa-microsoft"></i>
                        <span i18n="@@signInUsingMS">Sign in using Miscrosoft</span>
                    </a>
                </div>
            </div>
            <div *ngIf="loading" class="overlay">
                <i class="fas fa-sync fa-spin"></i>
            </div>
        </div>
    </div>
</div>